jQuery(function ($) {
  // ページアクセス時にモーダルウィンドウを表示する
  jQuery('#layer_board_area').layerBoard({
    alpha: 0.6,
    limitMin: 5,
    // 何分経過後に再度表示するか(分)
    limitCookie: 1 // cookie保存期間(日)
  });

  // var msg = "test";
  // $.cookie("testmessage", msg);
  // console.log(`保存されたクッキーは「${$.cookie("testmessage")}」です`);
});